import BasePage from '../../../../../components/table_page';
// import Form from './form';
// import DetailForm from './form/detail.vue';
// import ChangeForm from './form/form-change';
// import AdjustmentForm from './form/form-edit-adjust';
import DetialList from './form/detailList.vue';

export default {
  name: 'investment_type',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    // Form,
    // DetailForm,
    // ChangeForm,
    // AdjustmentForm,
    DetialList,
  },
  created() {
    this.getConfigList('budget_fee_lookup_list');
  },
  methods: {
    modalClick({ val, row }) {
      // if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
      //   this.formConfig = {
      //     ...this.formConfig,
      //     code: val.code,
      //     row,
      //   };
      //   this.modalConfig.title = val.name || '查看';
      //   this.openFull();
      // } else
      if (val.code === 'detail') {
        this.formName = 'DetialList';
        this.modalConfig = {
          title: '明细',
          width: '80%',
        };

        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
          feeBudgetCode: row.feeBudgetCode,
        };

        this.openDrawer();
      }
      //  else if (val.code === 'change') {
      //   this.formName = 'ChangeForm';
      //   this.modalConfig = {
      //     title: '变更',
      //     width: '60%',
      //   };

      //   this.formConfig = {
      //     ...this.formConfig,
      //     code: val.code,
      //     row,
      //   };

      //   this.openDrawer();
      // } else if (val.code === 'adjustment') {
      //   this.formName = 'AdjustmentForm';
      //   this.modalConfig = {
      //     title: '调整',
      //     width: '60%',
      //   };

      //   this.formConfig = {
      //     ...this.formConfig,
      //     code: val.code,
      //     row,
      //   };

      //   this.openDrawer();
      // }
    },
  },
};
