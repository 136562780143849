import BasePage from '@/found/components/table_page';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      params: {
        feeBudgetCode: '',
      },
    };
  },
  created() {
    this.params.feeBudgetCode = this.paramsProps.feeBudgetCode;
    this.getConfigList('tpm_budget_lookup_fee_detail');
  },
};
